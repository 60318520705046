/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
    var Sage = {
// All pages
        'common': {
            init: function () {
// JavaScript to be fired on all pages
//this.headerSlider();
                if (!window.location.origin) {
                    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
                }
            },
            theScrollerBtn: function () {

                function animToPos(data) {
                    $('html, body').stop().animate({
                        scrollTop: parseInt(data)
                    });
                }
                var rows = [];
                $('.scroll-segment').each(function (i) {
                    var thisOffs = $(this);
                    rows[i] = thisOffs;
                });
                $('.next-block').attr('data-next-target', rows[0].offset().top);
                $(window).scroll(function () {
                    var scrollTop = $(document).scrollTop();
                    var target = 0;
                    var last = rows.length - 1;

                    var docHeight = $(document).height();
                    var windHeight = window.innerHeight;

                    $('.next-block').children('span').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');

                    for (var inc in rows) {
                        if (rows[inc].offset().top <= scrollTop + 10) {
                            target = rows[parseInt(inc) + 1].offset().top;
                        } else if (scrollTop < rows[0].offset().top) {
                            target = rows[0].offset().top;
                        } else if (scrollTop === (docHeight - windHeight)) {
                            target = 0;
                            $('.next-block').children('span').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
                        }
                    }
                    $('.next-block').attr('data-next-target', target);
                });
                $('.next-block').on('click', function () {
                    var data = $(this).attr('data-next-target');
                    animToPos(data);
                });
            },
            headerSlider: function () {
                //$('.carousel').carousel();
            },
            dropDownMenus: function () {
                $('.nav .menu-item a').each(function () {
                    if ($(this).parent().has('ul').length > 0) {
                        $(this).on('click', function (e) {
                            e.preventDefault();
                        });
                        if ($(this).hasClass('search-ikon')) {
                            //do nothing
                        } else {
                            $(this).append(' <span class="glyphicon glyphicon-menu-down"></span>');
                        }
                    }
                });
                if (window.innerWidth > 992) {
//                    $('.nav .menu-item').hover(function () {
//                        if ($(this).has('ul').length > 0) {
//                            $(this).children('.sub-menu').show();
//                        }
//                    }, function () {
//                        if ($(this).has('ul').length > 0) {
//                            $(this).children('.sub-menu').hide();
//                        }
//                    });
//sd
                } else {
                    $('.nav>.menu-item>a').click(function (e) {
                        if ($(this).parent().has('.sub-menu').length > 0) {
                            e.preventDefault();
                            if ($(this).parent().hasClass('in')) {
                                $(this).parent().children('.sub-menu').children('.menu-item').css({
                                    opacity: 0,
                                    left: -20
                                });
                                $(this).parent().children('.sub-menu').hide();
                                $('.nav .menu-item').removeClass('in');
                            } else {
                                $('.nav .menu-item .sub-menu').hide();
                                $(this).parent().children('.sub-menu').fadeIn(300);
                                var delay = 100;
                                $(this).parent().children('.sub-menu').children('.menu-item').css({
                                    opacity: 0,
                                    left: -20
                                });
                                $(this).parent().children('.sub-menu').children('.menu-item').each(function () {
                                    $(this).delay(delay).animate({
                                        opacity: 1,
                                        left: 0
                                    }, 300);
                                    delay += 100;
                                });
                                $('.nav .menu-item').removeClass('in');
                                $(this).parent().addClass('in');
                            }
                        }
                    });
                    $('.hamburger-button').click(function () {
                        $(this).toggleClass('active');
                        $('nav').toggleClass('active');
                    });
                }
            },
            finalize: function () {
                this.theScrollerBtn();
                this.dropDownMenus();
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        cegtortenet: {
            init: function () {
                this.svg();
            },
            svg: function () {

            },
            finalize: function () {

            }
        },
        kapcsolat: {
            init: function () {
            },
            theMap: function () {
                // Create a map object and specify the DOM element for display.
                var iconFeature = new ol.Feature({
                    geometry: new ol.geom.Point(ol.proj.transform([17.554402, 47.118253], 'EPSG:4326', 'EPSG:3857')),
                    labelPoint: new ol.geom.Point(ol.proj.transform([17.554402, 47.118253], 'EPSG:4326', 'EPSG:3857')),
                    name: 'Bourns Kft.'
                });
                var iconStyle = new ol.style.Style({
                    image: new ol.style.Icon(/** @type {olx.style.IconOptions} */ ({
                        anchor: [0.5, 46],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        opacity: 1,
                        src: window.location.origin + '/app/themes/bourns/dist/images/marker.png'
                    }))
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new ol.source.Vector({
                    features: [iconFeature]
                });
                var vectorLayer = new ol.layer.Vector({
                    source: vectorSource
                });
                var rasterLayer = new ol.layer.Tile({
                    source: new ol.source.MapQuest({layer: 'osm'})
                });

                var map = new ol.Map({
                    interactions: ol.interaction.defaults({mouseWheelZoom: false}),
                    layers: [rasterLayer, vectorLayer],
                    target: 'contact-map',
                    view: new ol.View({
                        //center: [17.541852, 47.099026],
                        center: ol.proj.transform([17.554402, 47.118253], 'EPSG:4326', 'EPSG:3857'),
                        zoom: 14
                    })
                });
            },
            finalize: function () {
                this.theMap();
            }
        },
        menedzsment: {
            init: function(){
                this.removeMediaLinks();
            },
            removeMediaLinks: function(){
                $('.employees .media a').on('click', function(e){
                    e.preventDefault();
                });
            }
        },
        'nyitott_poziciok': {
            init: function () {
                this.mail_send();
                this.counter();
                this.confirm_message();
                this.accordion();
                $('.panel-group').collapse("hide");
            },
            accordion: function () {
                $('.collapse').on('shown.bs.collapse', function (e) {
                    var targetId = '#' + e.currentTarget.id;
                    if (e.currentTarget.id.indexOf('sub') === -1) {
                        $('html, body').animate({
                            scrollTop: $(targetId).offset().top - 47
                        }, 300);
                    }
                });
            },
            confirm_message: function () {
                var succ = $('.gform_confirmation_message').text();
                var err = $('.validation_error').text();
                err += ' ' + $('.validation_message').text();
                succ = succ.trim();
                err = err.trim();
                var message = '';
                if (succ !== '' && succ !== undefined) {
                    message = '<div class="alert alert-success text-center"><span class="glyphicon glyphicon-ok"></span> ' + succ + '</div>';
                }
                if (err !== '' && err !== undefined) {
                    message += '<div class="alert alert-danger text-center"><span class="glyphicon glyphicon-warning-sign"></span> ' + err + '</div>';
                }

                if (message !== '') {
                    var modalMsg = '<div id="confirm-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span class="closer-one"></span><span class="closer-two"></span></button></div><div class="modal-body">' + message + '</div></div></div></div>';
                    $(document).ready(function () {
                        $('#confirm-modal').modal('show');
                    });
                    $('.standard').first().prepend(modalMsg);
                    $('.gform_confirmation_message').html('<div class="col-xs-12">' + message + '</div>');
                }
//                $('.ginput_container_file_upload').each(function () {
//                    var uploaded = $(this).children('.ginput-preview');
//                    $(this).children('.upload_click').html(uploaded);
//                });
            },
            panel_collapse: function () {
                $('.panel-collapse .panel-heading a').click(function () {
                    $('html,body').delay(600).animate({
                        scrollTop: $(this).offset().top
                    }, 600);
                });
            },
            counter: function () {
                function startCounter() {
                    $('#employee-counter').prop('Counter', 0).animate({
                        Counter: $('#employee-counter').text()
                    }, {
                        duration: 4000,
                        easing: 'swing',
                        step: function (now) {
                            $('#employee-counter').text(Math.ceil(now));
                        }
                    });
                }
                var switcher = 0;
                /*
                 $(document).scroll(function () {
                 var limit = $(document).scrollTop() + window.innerHeight;
                 var offset = $('#employee-counter').offset().top;
                 if (limit > offset && switcher === 0) {
                 switcher = 1;
                 startCounter();
                 }
                 
                 });
                 */
            },
            init_facebook: function () {
                window.fbAsyncInit = function () {
                    FB.init({
                        appId: '1635881370014863',
                        xfbml: true,
                        version: 'v2.5'
                    });
                };
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {
                        return;
                    }
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "//connect.facebook.net/hu_HU/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            },
            share_job: function () {
                $('.share_on_facebook').on('click', function (e) {
                    e.preventDefault();
                    var jsonFbData = $(this).data('object');
                    FB.ui(jsonFbData, function (response) {
                    });
                });
            },
            mail_send: function () {
                $('.gform_hidden input[type=hidden]').each(function (i) {
                    var jobPosInput = $(this);
                    var jobPosParentPanel = jobPosInput.closest('.panel');
                    var theJobTitle = jobPosParentPanel.find('a h4.panel-title').text().trim();
                    jobPosInput.val(theJobTitle);
                });
            },
            replaceUpload: function () {
                $('input[type=file]').each(function () {
                    var fileID = $(this).attr('id');
                    var fileParent = $(this).parent();
                    fileParent.append('<a href="#" class="upload_click" data-id="' + fileID + '"><span class="icon-upload"></span> Önéletrajz feltöltése</a>');
                });
                $('.upload_click').click(function (e) {
                    e.preventDefault();
                    var dataID = $(this).data('id');
                    $('#' + dataID).trigger('click');
                });
                $('input[type="file"]').change(function () {
                    var dataid = $(this).attr('id');
                    if ($(this).val() !== '') {
                        var filePath = $(this).val();
                        $('.upload_click[data-id="' + dataid + '"]').html('<span class="glyphicon glyphicon-ok"></span> ' + filePath.replace(/^.*[\\\/]/, ''));
                    }
                });
            },
            finalize: function () {
                this.replaceUpload();
                this.init_facebook();
                this.share_job();
                $(document).ready(function () {
                    var anchor = window.location.hash.replace("#", "");
                    if (window.location.hash !== '') {
                        var target = $('#' + anchor);
                        var targetJobId = target.data('job-id');
                        var anchors = {
                            child: targetJobId.split('__')[0],
                            parent: targetJobId.split('__')[1]
                        };
                        $(".collapse").collapse('hide');
                        $("#" + anchors.parent).collapse('show');
                        $("#" + anchors.child).collapse('show');
                        var childOffset = $("#" + anchors.child).offset().top;
                        $('html,body').css({
                            scrollTop: childOffset
                        });
                    }
                });
            }
        },
        karrierut: {
            init: function () {
                // JavaScript to be fired on the about us page
                if (window.innerWidth > 992) {
                    this.graphicon();
                }
                $(window).resize(function () {
                    if (window.innerWidth > 992) {
                        this.graphicon();
                    }
                });
                this.carouselSetup();
            },
            carouselSetup: function () {
                function setCarouselControl() {
//                    var attitudeLinePos = $('#carousel-careers .carousel-inner .item.active .attitude-line').offset().top;
                    var relativeCenterY = $('#carousel-careers .carousel-inner .item.active .attitude-line').outerHeight() / 2;
                    var currentMedia = $('#carousel-careers .carousel-inner .item.active .media').outerHeight();
                    $('#carousel-careers .carousel-control').stop().animate({
                        top: currentMedia + relativeCenterY + 60
                    }, 200);
                }
                setCarouselControl();
                $('#carousel-careers').on('slid.bs.carousel', function () {
                    setCarouselControl();
                });
            },
            replaceUpload: function () {
                $('input[type=file]').each(function () {
                    var fileID = $('input[type="file"]').attr('id');
                    var fileParent = $('input[type="file"]').parent();
                    fileParent.append('<a href="#" class="upload_click" data-id="' + fileID + '"><span class="icon-upload"></span> Önéletrajz feltöltése</a>');
                });
                $('.upload_click').click(function (e) {
                    e.preventDefault();
                    var dataID = $(this).data('id');
                    $('#' + dataID).trigger('click');
                });
                $('input[type="file"]').each(function () {
                    var dataid = $(this).attr('id');
                    $(this).change(function () {
                        if ($(this).val() !== '') {
                            var filePath = $(this).val();
                            $('.upload_click[data-id="' + dataid + '"]').html('<span class="glyphicon glyphicon-ok"></span> ' + filePath.replace(/^.*[\\\/]/, ''));
                        }
                    });
                });
            },
            graphicon: function () {
                var set = document.getElementById('graphicon');
                set.height = window.innerHeight;
                set.width = window.innerWidth - 16;
                function pcalc(toset, wOrh) {
                    switch (wOrh) {
                        case 'w':
                            return Math.round(set.width * (toset / 100));
                        case 'h':
                            return Math.round(set.height * (toset / 100));
                    }
                }

                var obj = {
                    layer: true,
                    name: 'graphicon',
                    strokeWidth: 4,
                    fillStyle: '#99B6C8'
                };
                var pts = [
                    [0, pcalc(100, 'h')],
                    [pcalc(7, 'w'), pcalc(100, 'h')],
                    [pcalc(28, 'w'), pcalc(100, 'h')],
                    [pcalc(33, 'w'), pcalc(100, 'h')],
                    [pcalc(38, 'w'), pcalc(100, 'h')],
                    [pcalc(43, 'w'), pcalc(100, 'h')],
                    [pcalc(48, 'w'), pcalc(100, 'h')],
                    [pcalc(53, 'w'), pcalc(100, 'h')],
                    [pcalc(58, 'w'), pcalc(100, 'h')],
                    [pcalc(63, 'w'), pcalc(100, 'h')],
                    [pcalc(68, 'w'), pcalc(100, 'h')],
                    [pcalc(100, 'w'), pcalc(100, 'h')],
                    [pcalc(100, 'w'), pcalc(100, 'h')]
                ];
                var ptsAnimate = [
                    [0, pcalc(100, 'h')],
                    [pcalc(7, 'w'), pcalc(92, 'h')],
                    [pcalc(28, 'w'), pcalc(89, 'h')],
                    [pcalc(33, 'w'), pcalc(89, 'h')],
                    [pcalc(38, 'w'), pcalc(86, 'h')],
                    [pcalc(43, 'w'), pcalc(86, 'h')],
                    [pcalc(48, 'w'), pcalc(83, 'h')],
                    [pcalc(53, 'w'), pcalc(83, 'h')],
                    [pcalc(58, 'w'), pcalc(80, 'h')],
                    [pcalc(63, 'w'), pcalc(80, 'h')],
                    [pcalc(68, 'w'), pcalc(78, 'h')],
                    [pcalc(100, 'w'), pcalc(71, 'h')],
                    [pcalc(100, 'w'), pcalc(100, 'h')]
                ];
                var graphicon = $('#graphicon');
                for (var p = 0; p < pts.length; p += 1) {
                    obj['x' + (p + 1)] = pts[p][0];
                    obj['y' + (p + 1)] = pts[p][1];
                }
                graphicon.drawLine(obj);
                function drawMan(percent, x, y, i) {
                    graphicon.drawImage({
                        layer: true,
                        name: 'man' + i,
                        groups: ['man'],
                        scale: 0,
                        opacity: 0,
                        source: window.location.origin + '/app/themes/bourns/dist/images/career_man.png',
                        x: x, y: y - ((172 * percent) / 2),
                        height: 172 * percent,
                        width: 70 * percent
                    });
                }

                drawMan(0.3, pcalc(28, 'w'), pcalc(94, 'h') - 20, 1);
                drawMan(0.4, pcalc(38, 'w'), pcalc(94, 'h') - 20, 2);
                drawMan(0.65, pcalc(48, 'w'), pcalc(94, 'h') - 20, 3);
                drawMan(0.8, pcalc(58, 'w'), pcalc(94, 'h') - 20, 4);
                drawMan(1, pcalc(68, 'w'), pcalc(94, 'h') - 20, 5);
                for (var po = 0; po < ptsAnimate.length; po += 1) {
                    var currentPoint = {};
                    currentPoint['x' + (po + 1)] = ptsAnimate[po][0];
                    currentPoint['y' + (po + 1)] = ptsAnimate[po][1];
                    graphicon.animateLayer('graphicon', currentPoint, 200);
                }

                setTimeout(function () {
                    var manGroup = graphicon.getLayerGroup('man');
                    var delayTime = 300;
                    for (var man in manGroup) {
                        if (man >= 0) {
                            var current = manGroup[man];
                            graphicon.delayLayer(current.name, delayTime).animateLayer(current.name, {
                                scale: 1,
                                opacity: 1
                            }, 300);
                            delayTime += 300;
                        }
                    }
                }, 1000);
                graphicon.drawLine({
                    layer: true,
                    name: 'dashed_line',
                    strokeWidth: 4,
                    rounded: true,
                    strokeDash: [10],
                    strokeDashOffset: 5,
                    strokeStyle: '#ffffff',
                    x1: 0, y1: pcalc(94, 'h'),
                    x2: 0, y2: pcalc(94, 'h'),
                    x3: 0, y3: pcalc(94, 'h')
                });
                graphicon.delayLayer('dashed_line', 2000).animateLayer('dashed_line', {
                    x2: pcalc(82, 'w'),
                    x3: pcalc(82, 'w')
                }, 1400, function (layer) {
                    graphicon.animateLayer(layer, {
                        y3: pcalc(70, 'h')
                    });
                });
                //sd
                graphicon.drawText({
                    layer: true,
                    name: 'fejl',
                    fontSize: 20,
                    text: 'Fejlettségi szint',
                    textTransform: 'uppercase',
                    fillStyle: '#fff',
                    x: pcalc(82, 'w') + 22, y: pcalc(94, 'h') - 70,
                    rotate: 90,
                    opacity: 0
                }).drawText({
                    layer: true,
                    name: 'ido',
                    fontSize: 20,
                    text: 'Idő',
                    textTransform: 'uppercase',
                    fillStyle: '#fff',
                    x: pcalc(82, 'w') - 20, y: pcalc(94, 'h') + 22,
                    opacity: 0
                });
                graphicon.delayLayer('ido', 2600).animateLayer('ido', {
                    opacity: 1
                }, 400, function () {
                    graphicon.animateLayer('fejl', {
                        opacity: 1
                    });
                });
            },
            finalize: function () {
                this.replaceUpload();
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
